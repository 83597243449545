@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 37 99 235; /* blue-600 */
    --color-secondary: 107 114 128; /* gray-500 */
    --color-success: 22 163 74; /* green-600 */
    --color-danger: 220 38 38; /* red-600 */
    --color-warning: 234 179 8; /* yellow-500 */
    --color-info: 6 182 212; /* cyan-500 */
  }

  html, body {
    @apply text-gray-800 bg-gray-50;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

@layer components {
  /* Custom form styles that work with MUI and our own components */
  .input-label {
    @apply block text-sm font-medium text-gray-700 mb-1;
  }

  .input-field {
    @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm;
  }

  .form-error {
    @apply mt-1 text-sm text-red-600;
  }

  .form-helper {
    @apply mt-1 text-sm text-gray-500;
  }

  /* Badge component styles */
  .badge {
    @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;
  }

  .badge-success {
    @apply bg-green-100 text-green-800;
  }

  .badge-warning {
    @apply bg-yellow-100 text-yellow-800;
  }

  .badge-danger {
    @apply bg-red-100 text-red-800;
  }

  .badge-info {
    @apply bg-blue-100 text-blue-800;
  }

  .badge-neutral {
    @apply bg-gray-100 text-gray-800;
  }

  /* Status indicator styles */
  .status-dot {
    @apply relative inline-flex h-3 w-3 rounded-full mr-1.5;
  }

  .status-dot-active {
    @apply bg-green-400;
  }

  .status-dot-inactive {
    @apply bg-gray-300;
  }

  .status-dot-pending {
    @apply bg-yellow-400;
  }

  .status-dot-error {
    @apply bg-red-400;
  }
}